import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "../css/summary.css"
const PurchaseSummary = () => {
  const summaryData = JSON.parse(localStorage.getItem("summaryData")) || {
    buyer: "John Doe",
    date: "2024-11-20 10:00 AM",
    grandTotal: 500,
    creditTotal: 300,
    debitTotal: 200,
    checkedItems: [
      {
        code: "P001",
        name: "Product 1",
        quantity: 2,
        price: 50,
        total: 100,
        vendor: "Vendor A",
        capturedImage: "",
      },
      {
        code: "P002",
        name: "Product 2",
        quantity: 4,
        price: 100,
        total: 400,
        vendor: "Vendor B",
        capturedImage: "",
      },
    ],
  };

  const saveAsPdf = async () => {
    const pdf = new jsPDF();
    const content = document.getElementById("summary-details");
    const canvas = await html2canvas(content);
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(imgData, "PNG", 10, 10, 190, 0);

    if (summaryData.checkedItems.length > 0 && summaryData.checkedItems[0].capturedImage) {
      const img = new Image();
      img.src = summaryData.checkedItems[0].capturedImage
      img.onload = () => {
        pdf.addPage();
        pdf.addImage(img, "PNG", 10, 10, 190, 190);
        pdf.save(`purchase_summary(${summaryData.date}).pdf`);
      };
    } else {
      pdf.save(`purchase_summary(${summaryData.date}).pdf`);
    }
  };

  return (
    <> <div className="summary">
      <h1>Purchase Summary</h1>
      <div id="summary-details">
        <p>
          <strong>Buyer:</strong> {summaryData.buyer}
        </p>
        <p>
          <strong>Date and Time:</strong> {summaryData.date}
        </p>
        <p>
          <strong>Grand Total:</strong> ${summaryData.grandTotal}
        </p>
        <p>
          <strong>Credit Total:</strong> ${summaryData.creditTotal}
        </p>
        <p>
          <strong>Debit Total:</strong> ${summaryData.debitTotal}
        </p>
        <table>
          <thead>
            <tr>
              <th>SKU Code</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Total Price</th>
              <th>Price/Item</th>
              <th>Vendor Name</th>
            </tr>
          </thead>
          <tbody>
            {summaryData.checkedItems.map((item, index) => (
              <tr key={index}>
                <td>{item.code}</td>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>${item.price}</td>
                <td>${item.total}</td>
                <td>{item.vendor}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {summaryData.checkedItems.length > 0 && summaryData.checkedItems[0].capturedImage && (
          <div>
            <h2>Captured Image</h2>
            <img
              src={summaryData.checkedItems[0].capturedImage}
              alt="Captured"
            />
          </div>
        )}
      </div>
      <button id="save-pdf-button" onClick={saveAsPdf}>
        Save as PDF
      </button>
    </div></>
  );
};

export default PurchaseSummary;