// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ItemManagement from './components/ItemManagement';
import PurchaseSummary from './components/PurchaseSummary';



const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ItemManagement />} />
        <Route path="/summary" element={<PurchaseSummary />} />
      </Routes>
    </Router>
  );
};

export default App;