import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './PurchaseSummary';

const ItemManagement = () => {
  const [buyer, setBuyer] = useState('');
  const [uncheckedItems, setUncheckedItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [creditTotal, setCreditTotal] = useState(0);
  const [debitTotal, setDebitTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [newProduct, setNewProduct] = useState({
    code: '',
    name: '',
    quantity: '',
    price: '',
    vendor: '',
    payment: 'credit',
    unit: 'box',
    customUnit: ''
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const dataProducts = async () => {
      try {

      //   const response = await fetch('http://64.225.84.238:5000/getProducts', {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/json',
      //     },
      // });
        const response = await fetch('https://viowebs.in/getProducts', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        const result = await response.json();
        
        if (response.ok) {
          const uncheck = result.map((data) => ({
            ProductId: data.product_id,
            ProductName: data.product_name,
            price: 0,
            total: 0,
            payment: 'credit',
            unit: 'box',
            customUnit: '',
          }));

          setUncheckedItems(uncheck);
        } else {
          alert(`Error: ${result.error || 'Unknown error'}`);
        }
      } catch (error) {
        
        console.error('Error fetching data:', error);
        alert('Error fetching data');
      }
    };

    dataProducts();

    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredItems = uncheckedItems.filter(
    (item) =>
      item.ProductId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.ProductName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleBuyerChange = (e) => {
    setBuyer(e.target.value);
    setWarningMessage('');
  };

  const calculateRowTotal = (quantity, price) => (price / quantity).toFixed(2);

  const updateItemTotal = (item, quantity, price) => {
    item.quantity = quantity;
    item.price = price;
    item.total = calculateRowTotal(quantity, price);
    setUncheckedItems([...uncheckedItems]);
  };

  const handlePaymentChange = (item, payment) => {
    item.payment = payment;
    setUncheckedItems([...uncheckedItems]);
  };

  const handleUnitChange = (item, unit) => {
    item.unit = unit;
    item.customUnit = unit === 'other' ? '' : item.customUnit;
    setUncheckedItems([...uncheckedItems]);
  };

  const handleCustomUnitChange = (item, customUnit) => {
    item.customUnit = customUnit;
    setUncheckedItems([...uncheckedItems]);
  };

  const handleItemPurchase = (item) => {
    setUncheckedItems(uncheckedItems.filter((i) => i.ProductId !== item.ProductId));
    setCheckedItems([...checkedItems, item]);
    updateTotals(item);
  };

  const updateTotals = (item) => {
    let newGrandTotal = grandTotal + parseFloat(item.total || 0);
    let newCreditTotal = creditTotal;
    let newDebitTotal = debitTotal;

    if (item.payment === 'credit') {
      newCreditTotal += parseFloat(item.total || 0);
    } else if (item.payment === 'debit') {
      newDebitTotal += parseFloat(item.total || 0);
    }

    setGrandTotal(newGrandTotal);
    setCreditTotal(newCreditTotal);
    setDebitTotal(newDebitTotal);
  };

  const handleAddNewProduct = () => {
    const { code, name, quantity, price, vendor, payment, unit, customUnit } = newProduct;

    if (!code || !name || !quantity || !price || !vendor) {
      alert('Please fill in all fields.');
      return;
    }

    const total = calculateRowTotal(quantity, price);
    setCheckedItems([
      ...checkedItems,
      { ProductId: code, ProductName: name, quantity, price, total, vendor, payment, unit, customUnit }
    ]);
    setNewProduct({ code: '', name: '', quantity: '', price: '', vendor: '', payment: 'credit', unit: 'box', customUnit: '' });
    setModalOpen(false);
    updateTotals({ total, payment, unit, customUnit });
  };
  

  const submitRaw = async () => {
    const summaryData = {
      buyer,
      checkedItems: checkedItems.map(item => ({
        code: item.ProductId,
        name: item.ProductName,
        quantity: item.quantity,
        price: item.price,
        total: item.total,
        vendor: item.vendor,
        capturedImage // Include captured image in summary data
      })),
      grandTotal,
      creditTotal,
      debitTotal,
      date: new Date().toLocaleString()
    };

    try {
      const response = await fetch('https://viowebs.in/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(summaryData),
      });

      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        localStorage.setItem('summaryData', JSON.stringify(summaryData)); // Store summary data for the summary page
        navigate('/summary');
      } else {
        alert(`Error: ${result.error}`);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Error submitting data');
    }
  };

  const calculateGrandTotal = () => {
    let grandTotal = 0;
    let creditTotal = 0;
    let debitTotal = 0;
    if (Array.isArray(checkedItems)) {
      checkedItems.forEach((data) => {
        const { price, payment } = data || {};
        if (price) { 
          grandTotal += price;
          if (payment === "credit") {
            creditTotal += price;
          } else if (payment === "debit") {
            debitTotal += price;
          }
        }
      });
    }
    setGrandTotal(grandTotal);
    setCreditTotal(creditTotal);
    setDebitTotal(debitTotal);
  };

  useEffect(() => {
    calculateGrandTotal();
  }, [checkedItems]);

  const handleCaptureImage = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    videoRef.current.srcObject = stream;
    videoRef.current.play();

    setTimeout(() => {
      const canvas = canvasRef.current;
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;

      const context = canvas.getContext('2d');
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const imageDataUrl = canvas.toDataURL('image/png');
      setCapturedImage(imageDataUrl);

      stream.getTracks().forEach(track => track.stop());
    }, 10000);
  };

  return (
    <div className="container">
      <div className="heading">
        <h1><span id='d'>Deliver</span><span id='f'>Fresh</span></h1>
        <h1>Purchase Management</h1>
        <span id="current-time">{currentTime}</span>
      </div>
      <div id="controls">
        <div>
          <label htmlFor="search-bar">Search:</label>
          <input
            type="text"
            id="search-bar"
            placeholder="Search items..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <div className="buyer-search-container">
          <label htmlFor="buyer-dropdown">Select Buyer:</label>
          <select id="buyer-dropdown" value={buyer} onChange={handleBuyerChange}>
            <option value="" disabled>Select Buyer</option>
            <option value="Samba">Samba</option>
            <option value="Rajababu">Rajababu</option>
            <option value="Lateesh">Lateesh</option>
          </select>
        </div>
      </div>

      <div className="flex">
        <div className="cre">
          <div id="credit-total-container">Credit Total: ${creditTotal ? parseInt(creditTotal).toFixed(2) : 0}</div>
          <div id="debit-total-container">Debit Total: ${debitTotal ? parseInt(debitTotal).toFixed(2) : 0}</div>
          <div id="grand-total-container">Grand Total: ${grandTotal ? parseInt(grandTotal).toFixed(2) : 0}</div>
        </div>
        <div id="submit-container">
          <button onClick={submitRaw}>Submit</button>
          <button onClick={() => setModalOpen(true)}>Add New Product</button>
        </div>
      </div>

      <div id="warning-message" className="warning">{warningMessage}</div>

      <div  style={{ display: 'flex',
        marginTop:"15px",
        marginBottom:"15px",
         alignItems: 'center' }}>
        <video ref={videoRef} style={{ border: '1px solid black', borderRadius: '5px', width: '200px', height: '150px' }} />
        {capturedImage && <img src={capturedImage} alt="Captured" style={{ border: '1px solid black', borderRadius: '5px', width: '200px', height: '150px' }} />}
        <canvas ref={canvasRef} style={{ width: '150px', height: '150px', display: 'none' }} />
      </div>
      <button onClick={handleCaptureImage} id="submit-container2">Capture Image</button>

      <div className="section-heading">Checked Items</div>
      <table id="checked-item-table">
        <thead>
          <tr>
            <th>SKU Code</th>
            <th>Product Name</th>
            <th>Quantity</th>
            <th>Unit</th>
            <th>Total Price</th>
            <th>Price/Item</th>
            <th>Vendor Name</th>
            <th>Payment</th>
          </tr>
        </thead>
        <tbody>
          {checkedItems.map((item) => (
            <tr key={item.ProductId}>
              <td>{item.ProductId}</td>
              <td>{item.ProductName}</td>
              <td>{item.quantity}</td>
              <td>{item.unit === 'other' ? item.customUnit : item.unit}</td>
              <td>${item.price}</td>
              <td>${item.total}</td>
              <td>{item.vendor}</td>
              <td>{item.payment}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="section-heading">Unchecked Items</div>
      <table id="unchecked-item-table">
        <thead>
          <tr>
            <th>SKU Code</th>
            <th>Product Name</th>
            <th>Quantity</th>
            <th>Unit</th>
            <th>Total Price</th>
            <th>Price/Item</th>
            <th>Vendor Name</th>
            <th>Payment</th>
            <th>Purchased</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((item) => (
            <tr key={item.ProductId}>
              <td>{item.ProductId}</td>
              <td>{item.ProductName}</td>
              <td>
                <input
                  type="number"
                  min="0"
                  placeholder="Quantity"
                  onChange={(e) => updateItemTotal(item, parseFloat(e.target.value) || 0, item.price)}
                />
              </td>
              <td>
                {item.unit === 'other' ? (
                  <input
                    type="text"
                    placeholder="Enter unit"
                    value={item.customUnit}
                    onChange={(e) => handleCustomUnitChange(item, e.target.value)}
                  />
                ) : (
                  <select
                    value={item.unit}
                    onChange={(e) => handleUnitChange(item, e.target.value)}
                  >
                    <option value="box">Box</option>
                    <option value="kg">Kg</option>
                    <option value="bunch">Bunch</option>
                    <option value="each">Each</option>
                    <option value="other">Other</option>
                  </select>
                )}
              </td>
              <td>
                <input
                  type="number"
                  min="0"
                  step="0.01"
                  placeholder="Price"
                  onChange={(e) => updateItemTotal(item, item.quantity, parseFloat(e.target.value) || 0)}
                />
              </td>
              <td>${item.total}</td>
              <td>
                <input
                  type="text"
                  placeholder="Vendor Name"
                  onChange={(e) => (item.vendor = e.target.value)}
                />
              </td>
              <td>
                <label>
                  <input
                    type="radio"
                    name={`payment-${item.ProductId}`}
                    value="credit"
                    checked={item.payment === 'credit'}
                    onChange={() => handlePaymentChange(item, 'credit')}
                  />
                  Credit
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    name={`payment-${item.ProductId}`}
                    value="debit"
                    checked={item.payment === 'debit'}
                    onChange={() => handlePaymentChange(item, 'debit')}
                  />
                  Debit
                </label>
              </td>
              <td>
                <button onClick={() => handleItemPurchase(item)} disabled={!item.payment} id="submit-container3">Purchase</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {
  modalOpen && (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
        <h2>Add New Product</h2>
        <input
          type="text"
          placeholder="Item Code"
          value={newProduct.code}
          onChange={(e) => setNewProduct({ ...newProduct, code: e.target.value })}
        />
        <input
          type="text"
          placeholder="Product Name"
          value={newProduct.name}
          onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
        />
        <input
          type="number"
          placeholder="Quantity"
          value={newProduct.quantity}
          onChange={(e) => setNewProduct({ ...newProduct, quantity: e.target.value })}
        />
        {newProduct.unit === 'other' ? (
          <input
            type="text"
            placeholder="Enter unit"
            value={newProduct.customUnit}
            onChange={(e) => setNewProduct({ ...newProduct, customUnit: e.target.value })}
          />
        ) : (
          <select
            value={newProduct.unit}
            onChange={(e) => setNewProduct({ ...newProduct, unit: e.target.value })}
          >
            <option value="box">Box</option>
            <option value="kg">Kg</option>
            <option value="bunch">Bunch</option>
            <option value="each">Each</option>
            <option value="other">Other</option>
          </select>
        )}
        <input
          type="number"
          placeholder="Total Price"
          value={newProduct.price}
          onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
        />
        <input
          type="text"
          placeholder="Vendor Name"
          value={newProduct.vendor}
          onChange={(e) => setNewProduct({ ...newProduct, vendor: e.target.value })}
        />
        

        {/* Add Payment Radio Buttons */}
        <div>
          <label>
            <input
              type="radio"
              name="payment"
              value="credit"
              checked={newProduct.payment === 'credit'}
              onChange={(e) => setNewProduct({ ...newProduct, payment: e.target.value })}
            />
            Credit
          </label>
          <label>
            <input
              type="radio"
              name="payment"
              value="debit"
              checked={newProduct.payment === 'debit'}
              onChange={(e) => setNewProduct({ ...newProduct, payment: e.target.value })}
            />
            Debit
          </label>
        </div>

        <button onClick={handleAddNewProduct} id="submit-container1">Add Product</button>
      </div>
    </div>
  )
}

    </div>
  );
};

export default ItemManagement;
